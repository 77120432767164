
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
    apiKey: "AIzaSyBWEw9F4rAztOcmQHWRrhnel4F-Dn4PAys",
    authDomain: "mak-org.firebaseapp.com",
    projectId: "mak-org",
    storageBucket: "mak-org.firebasestorage.app",
    messagingSenderId: "563525519480",
    appId: "1:563525519480:web:578f0da4d3bf7532b9858d",
    measurementId: "G-ETE0HLDW1T"

};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;