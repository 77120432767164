import React from 'react'
import Navbar from '../inc/Navbar'
import UpcomingEvents from './UpcomingEvents'
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
function Eventspage() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <ScrollToTop smooth top="100" color="darkBlue"/>
        <div className='mountainerindian'>
                <div className="album py-1">
                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li style={{ color: "black" }} className="breadcrumb-item"><Link to="/" style={{ color: "black" }} href="#"><small>Home</small></Link></li>
                                <li className="breadcrumb-item active" aria-current="page"><small>Upcoming Events</small></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        <UpcomingEvents/>
        <br/>
            </div>
  )
}

export default Eventspage