import React from 'react'

function Mapg() {
  return (
    <div>

<div className="album py-0">
                <div className="container">
                    <div className="row">
<iframe  className="iframe-fluid" height="300" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Mountaineers'%20Association%20of%20krishnanagar(MAK),%209,%20Church%20Rd,%20Nagendranagar,%20Krishnanagar,%20West%20Bengal%20741101%20+(Mountaineers'%20Association%20of%20krishnanagar(MAK),%209,%20Church%20Rd,%20Nagendranagar,%20Krishnanagar,%20West%20Bengal%20741101)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
    </div>
    </div>
    </div>
    </div>
  )
}

export default Mapg