import React from 'react'
import makmemberphoto from "../images/mak photo member.jpeg";

function About() {
  return (
    <div>
          <div className="album py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mt-3">
                                <div className="card-body">
                                    <h1 className="card-title mb-3">About <b>MAK</b></h1>
                                    <h4 className="card-text"><b>Dare to unvail the unknown</b> 
                                    </h4>
                                    <h6 className='justify mb-2'>Mountaineers’ Association of Krishnanagar is at present a notable name in West Bengal nay India in the field of mountaineering. Since inception in 1986, our club has been conducting mountaineering expeditions, high altitude trekking in the Himalayas, rock climbing courses in order to attract the youngsters towards mountains at regular courses and also to rouse craving for adventure in children, we hold Adventure –cum- Nature Study Camp every year under the banner of our club. The activities of our club do not stop here. We even arrange for Medical Camps from time to time in the remotest areas of the Himalayas with a view to providing the poor hill men with the fruits of modern treatment. Moreover, urged by the social obligation, our club has been marked for its outstanding contribution in the organization of Blood Donation Camp and cultural programmes like ‘Homage to Vivekananda’ and so on.</h6>
                                   <h5>It is registered under Societies Registration Act and also affiliated to the Indian Mountaineering Foundation, New Delhi.</h5>
                                
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                          <div className='rediusfuchkabanner'>
                                <img src={makmemberphoto} className="card-img-top" alt="makmemberphoto"/>
                               
                                </div>
                            
                        </div>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default About