import React from 'react'
import Navbar from '../inc/Navbar'
import { Link } from "react-router-dom";
import Ramjak2021 from "../images/Ramjak Leader's Report 2024.pdf";
import ramjak1 from "../images/1.jpg";
import ramjak2 from "../images/2.jpg";
import ramjak3 from "../images/3.jpg";

import ramjak4 from "../images/4.jpg";

import ramjak5 from "../images/11.jpg";
import ramjak6 from "../images/6.jpg";
import ramjak7 from "../images/7.jpg";
function Ramjak() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className='mountainerindian'>
                <div className="album py-1">
                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li style={{ color: "black" }} className="breadcrumb-item"><Link to="/" style={{ color: "black" }} href="#"><small>Home</small></Link></li>
                                <li style={{ color: "black" }} className="breadcrumb-item"><Link to="/expedition-report" style={{ color: "black" }} href="#"><small>Back</small></Link></li>
                                <li className="breadcrumb-item active" aria-current="page"><small>Ramjak 2021</small></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            {/* <div className="album py-1">
                <div className="container">

                    <a href={Tharkot1994report} className="makreports" target="_blank" rel="noopener noreferrer"> Download <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cloud-download" viewBox="0 0 16 16">
                        <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383" />
                        <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708z" />
                    </svg></a>
                </div>
            </div> */}

            <div className="album py-2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 mt-3">
                            <a href={Ramjak2021} className="makreports" target="_blank" rel="noopener noreferrer"> Download <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cloud-download" viewBox="0 0 16 16">
                                <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383" />
                                <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708z" />
                            </svg></a>
                        </div>
                        <div className="col-md-3 mt-3">
                            <img src={ramjak1} className="card-img-top" alt="ramjak" />
                        </div>
                        <div className="col-md-3 mt-3">
                            <img src={ramjak2} className="card-img-top" alt="ramjak" />
                        </div>
                        <div className="col-md-3 mt-3">
                            <img src={ramjak3} className="card-img-top" alt="ramjak" />
                        </div>
                        <div className="col-md-3 mt-3">
                            <img src={ramjak4} className="card-img-top" alt="ramjak" />
                        </div>

                        <div className="col-md-3 mt-3">
                            <img src={ramjak5} className="card-img-top" alt="ramjak" />
                        </div>


                        <div className="col-md-3 mt-3">
                            <img src={ramjak6} className="card-img-top" alt="ramjak" />
                        </div>
                        <div className="col-md-3 mt-3">
                            <img src={ramjak7} className="card-img-top" alt="ramjak" />
                        </div>
                    </div>
                </div>
            </div>
            <br />
        </div>
    )
}

export default Ramjak