import React from 'react'
import Navbar from '../inc/Navbar'
import ExpeditionShortnote from './ExpeditionShortnote'
import ScrollToTop from "react-scroll-to-top";
import { Link } from "react-router-dom";


function ExpeditionShortnotepage() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <ScrollToTop smooth top="100" color="darkBlue" />
            <div className='mountainerindian'>
                <div className="album py-1">

                    <div className="container mt-3">

                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Expedition Short Note</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className='backcolor'>
                <ExpeditionShortnote />
                <br />
            </div>
        </div>
    )
}

export default ExpeditionShortnotepage