import React from 'react'
import Navbar  from '../inc/Navbar'

import { Link } from "react-router-dom";

function Membermak() {
  return (
    <div>
        <Navbar />
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <div className='mountainerindian'>
                <div className="album py-1">
                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li style={{ color: "black" }} className="breadcrumb-item"><Link to="/" style={{ color: "black" }} href="#"><small>Home</small></Link></li>
                                <li className="breadcrumb-item active" aria-current="page"><small>Member</small></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

           

            <br/>
            <div className="album py-1">
            <div className="container mt-3">
                <h5>Update soon</h5>
                </div>
                </div>
                <br/>
                <br/>
            <br/>

</div>
)
}

export default Membermak