import React from 'react'
import Navbar from '../inc/Navbar'
import About from './About'
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
function Aboutpage() {
  return (
    <div>
      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <ScrollToTop smooth top="100" color="darkBlue" />
      <div className='mountainerindian'>
        <div className="album py-1">

          <div className="container mt-3">

            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">About us</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <About />
      <br />
    </div>
  )
}

export default Aboutpage