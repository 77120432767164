import React from 'react'
import Navbar from '../inc/Navbar'
import MakOrganises from './MakOrganises'
import ScrollToTop from "react-scroll-to-top";
import { Link } from "react-router-dom";

function Makorganisespage() {
  return (
    <div>
         <Navbar />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      
      <ScrollToTop smooth top="100" color="darkBlue" />
      <div className='mountainerindian'>
        <div className="album py-1">

          <div className="container mt-3">

            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Expeditions</li>
              </ol>
            </nav>
          </div>
        </div>
        </div>
      <MakOrganises/>

    </div>
  )
}

export default Makorganisespage