import React from 'react'
import Navbar from '../inc/Navbar'
import Slider from '../inc/Slider'
import "./Home.css";
import summercamp from "../images/SUMMER_CAMP_FORM_2025.pdf";
import About from './About';
import Mapg from './Mapg';
import Gallery from './Gallery';
import ScrollToTop from "react-scroll-to-top";

import BasantaSinghaRoy from "../images/im2.jpg"
import BasantaSinghaRoyim11 from "../images/im11.jpg";
import BasantaSinghaRoyim6 from "../images/im6.jpg";
import BasantaSinghaRoyimage7 from "../images/im7.jpg";
import UpcomingEvents from './UpcomingEvents';
import MakOrganises from './MakOrganises';
import ExpeditionShortnote from './ExpeditionShortnote';



function Home() {
  return (
    <div>
      <Navbar />
      <br />
      <br />
      <br />
<br/>

      <ScrollToTop smooth top="100" color="darkBlue"/>
      <div className="album py-2">
        <div className="container">
          <h6>Mountaineers’ Association of Krishnanagar</h6>
        </div>
      </div>


      <Slider />
      <div className='Mountaineersindia'>
        <div className="album py-0">
          <div className="container">
            <marquee>
              <div className='tastenew'>
                <h4 style={{ color: "white" }} className='mt-2'>Welcome to Mountaineers’ Association of Krishnanagar  <b className='application'> Application form for 20th Adventure -cum- Nature Study (Summer) Camp, 2025 </b><a style={{ color: "white" }} href={summercamp} target="_blank" rel="noopener noreferrer">Download <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-cloud-arrow-down blink" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M7.646 10.854a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 9.293V5.5a.5.5 0 0 0-1 0v3.793L6.354 8.146a.5.5 0 1 0-.708.708z" />
              <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383m.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
            </svg></a> </h4>

              </div>
            </marquee>
          </div>
        </div>
      </div>

      <About />
      <br />
        <div className="album py-1">
                    <div className="container">
                      <h1 style={{color:"darkblue"}}>Upcoming <b>Events</b></h1>
                      </div>
                    </div>
    <UpcomingEvents/>

      <div className="album py-2">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mt-3">
              <div className='borderradius'>
                <img src={BasantaSinghaRoy} className="card-img-top" alt="BasantaSinghaRoy_Mountaineer"/>
              </div>

            </div>
            <div className="col-md-6 mt-3">
              <div className='borderradius'>
                <img src={BasantaSinghaRoyim11} className="card-img-top" alt="BasantaSinghaRoy_Mountaineer" />
              </div>

            </div>
            <div className="col-md-6 mt-3">
              <div className='borderradius'>
                <img src={BasantaSinghaRoyim6} className="card-img-top" alt="BasantaSinghaRoy_Mountaineer" />
              </div>

            </div>
            <div className="col-md-6 mt-3">
              <div className='borderradius'>
                <img src={BasantaSinghaRoyimage7} className="card-img-top" alt="BasantaSinghaRoy_Mountaineer" />
              </div>

            </div>
          </div>
        </div>
      </div>
      <br />
      <div className='backcolor'>
      <ExpeditionShortnote/>
      </div>
      <MakOrganises/>
      
      <div className="album py-0">
        <div className="container">
          <center>
            <div className='coloractivity'>
              <h1 className='mb-3'><b>ACTIVITY</b></h1>
            </div>
            <h6>Mountaineers’ Association of Krishnanagar</h6>

          </center>
        </div>
      </div>

      <div className="album py-4">
        <div className="container">
          <div className="row">
            <div className="col-md-4 mt-4">
              <center>
                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" style={{ color: "darkBlue" }} className="bi bi-flag" viewBox="0 0 16 16">
                  <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21 21 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21 21 0 0 0 14 7.655V1.222z" />
                </svg>

                <div className="card-body">

                  <h6 className="card-text mt-2 mb-3"><b>Mountaineering Expedition in the Himalayas (once a year)</b></h6>


                </div>
              </center>
            </div>

            <div className="col-md-4 mt-4">
              <center>
                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" style={{ color: "darkBlue" }} className="bi bi-flag" viewBox="0 0 16 16">
                  <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21 21 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21 21 0 0 0 14 7.655V1.222z" />
                </svg>

                <div className="card-body">

                  <h6 className="card-text mt-2 mb-3"><b>Rock Climbing course (once a year)</b></h6>


                </div>
              </center>
            </div>

            <div className="col-md-4 mt-4">
              <center>
                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" style={{ color: "darkBlue" }} className="bi bi-flag" viewBox="0 0 16 16">
                  <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21 21 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21 21 0 0 0 14 7.655V1.222z" />
                </svg>
                <div className="card-body">
                  <h6 className="card-text mt-2 mb-3"><b>Adventure-cum Nature Study Camp (once a year)</b></h6>
                </div>
              </center>
            </div>

            <div className="col-md-4 mt-4">
              <center>
                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" style={{ color: "darkBlue" }} className="bi bi-flag" viewBox="0 0 16 16">
                  <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21 21 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21 21 0 0 0 14 7.655V1.222z" />
                </svg>
                <div className="card-body">
                  <h6 className="card-text mt-2 mb-3"><b>High Altitude Trekking in the Himalayas (1 or 2 times a year)</b></h6>
                </div>
              </center>
            </div>

            <div className="col-md-4 mt-4">
              <center>
                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" style={{ color: "darkBlue" }} className="bi bi-flag" viewBox="0 0 16 16">
                  <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21 21 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21 21 0 0 0 14 7.655V1.222z" />
                </svg>
                <div className="card-body">
                  <h6 className="card-text mt-2 mb-3"><b>Practice Camp at Susunia Hills, Mathaburu Hills etc. (3/4 times a year)</b></h6>
                </div>
              </center>
            </div>

            <div className="col-md-4 mt-4">
              <center>
                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" style={{ color: "darkBlue" }} className="bi bi-flag" viewBox="0 0 16 16">
                  <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21 21 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21 21 0 0 0 14 7.655V1.222z" />
                </svg>
                <div className="card-body">
                  <h6 className="card-text mt-2 mb-3"><b>Medical Camp and Summer Camp in the Himalayas (once a year)</b></h6>
                </div>
              </center>
            </div>
            <div className="col-md-4 mt-4">
              <center>
                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" style={{ color: "darkBlue" }} className="bi bi-flag" viewBox="0 0 16 16">
                  <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21 21 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21 21 0 0 0 14 7.655V1.222z" />
                </svg>
                <div className="card-body">
                  <h6 className="card-text mt-2 mb-3"><b>Regular Slide Show on mountaineering and adventure activities</b></h6>
                </div>
              </center>
            </div>
            <div className="col-md-4 mt-4">
              <center>
                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" style={{ color: "darkBlue" }} className="bi bi-flag" viewBox="0 0 16 16">
                  <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21 21 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21 21 0 0 0 14 7.655V1.222z" />
                </svg>
                <div className="card-body">
                  <h6 className="card-text mt-2 mb-3"><b>Publication of Journal SAGARMATHA (once in 2 years)</b></h6>
                </div>
              </center>
            </div>
            <div className="col-md-4 mt-4">
              <center>
                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" style={{ color: "darkBlue" }} className="bi bi-flag" viewBox="0 0 16 16">
                  <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21 21 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21 21 0 0 0 14 7.655V1.222z" />
                </svg>
                <div className="card-body">
                  <h6 className="card-text mt-2 mb-3"><b>Blood Donation</b></h6>
                </div>
              </center>
            </div>
          </div>
        </div>

      </div>

      <br />





      <div className='gallerycolor'>
        <div className="album py-0">
          <div className="container">
            <h1><b>Gallery</b></h1>
          </div>
        </div>
      </div>
      <Gallery />
      <br />
      <br />
      <Mapg />
      <br />
      <br />
    </div>
  )
}

export default Home