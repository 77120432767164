import './App.css';

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';


import Home from "./components/pages/Home";
import Footer from "./components/inc/Footer";
import Aboutpage from './components/pages/Aboutpage';
import Contactus from './components/pages/Conatctus';
import Gallerypage from './components/pages/Gallerypage';
import ReportExpedition from './components/pages/ReportExpedition';
import Membermak from './components/pages/Membermak';
import Changuch2009 from './components/pages/Changuch2009';
import Gangstang1990 from './components/pages/Gangstang1990';
import Thalaysagar2008 from './components/pages/Thalaysagar2008';
import Kalu2007 from './components/pages/Kalu2007';
import Setaglacier1992 from './components/pages/Setaglacier1992';
import Srikailash1993 from './components/pages/Srikailash1993';
import Tharkot1994 from './components/pages/Tharkot1994';
import Kamet1997 from './components/pages/Kamet1997';
import Choukhamba1998 from './components/pages/Choukhamba1998';
import Bhrigupanth1999 from './components/pages/Bhrigupanth1999';
import Nandakot2000 from './components/pages/Nandakot2000';
import Annapurna2012 from './components/pages/Annapurna2012';
import Baljuri1991 from './components/pages/Baljuri1991';

import Eventspage from './components/pages/Eventspage';
import Makorganisespage from './components/pages/Makorganisespage';
import ExpeditionShortnotepage from './components/pages/ExpeditionShortnotepage';
import Panwaliduar2006 from './components/pages/Panwaliduar2006';
import Shivling2005 from './components/pages/Shivling2005';
import Indrasan2004 from './components/pages/Indrasan2004';
import Indrasan2003 from './components/pages/Indrasan2003';
import Shiva2002 from './components/pages/Shiva2002';
import Dharamsura2012 from './components/pages/Dharamsura2012';
import Ramjak from './components/pages/Ramjak';
import Gorichen from './components/pages/Gorichen';
import Nun2015 from './components/pages/Nun2015';
function App() {
  return (
    <Router>
        
    <div>
      <Routes>
      <Route axact path="/" element={<Home/>}/>
      <Route axact path="/about-us" element={<Aboutpage/>}/>
      <Route axact path="/contact-us" element={<Contactus/>}/>
      <Route axact path="/gallery" element={<Gallerypage/>}/>
      <Route axact path="/expedition-report" element={<ReportExpedition/>}/>
      <Route axact path="/member" element={<Membermak/>}/>
      <Route axact path="Changuch-2009" element={<Changuch2009/>}/>
      <Route axact path="Gangstang-1990" element={<Gangstang1990/>}/>
      <Route axact path="thalaysagar-2008" element={<Thalaysagar2008/>}/>
      <Route axact path="Kulumakalu-rubalkang-2007" element={<Kalu2007/>}/>
      <Route axact path="Setaglacier-1992" element={<Setaglacier1992/>}/>
      <Route axact path="Srikailash-1993" element={<Srikailash1993/>}/>
      <Route axact path="Tharkot-1994" element={<Tharkot1994/>}/>
      <Route axact path="Kamet-1997" element={<Kamet1997/>}/>
      <Route axact path="Choukhamba-1998" element={<Choukhamba1998/>}/>
      <Route axact path="Bhrigupanth-1999" element={<Bhrigupanth1999/>}/>
      <Route axact path="Nandakot-2000" element={<Nandakot2000/>}/>
      <Route axact path="Annapurna-2012" element={<Annapurna2012/>}/>
      <Route axact path="Baljuri-1991" element={<Baljuri1991/>}/>
      <Route axact path="panwaliduar-2006" element={<Panwaliduar2006/>}/>
      <Route axact path="Shivling-2005" element={<Shivling2005/>}/>
      <Route axact path="Indrasan-2004" element={<Indrasan2004/>}/>
      <Route axact path="Indrasan-2003" element={<Indrasan2003/>}/>
      <Route axact path="Shiva-2002" element={<Shiva2002/>}/>
      <Route axact path="Dharamsura-2012" element={<Dharamsura2012/>}/>
      <Route axact path="Ramjak-2021" element={<Ramjak/>}/>
      <Route axact path="gorichen-2024" element={<Gorichen/>}/>
      <Route axact path="nun-2015" element={<Nun2015/>}/>
      <Route axact path="Upcoming-events" element={<Eventspage/>}/>
      <Route axact path="MAK-organises-following-expeditions" element={<Makorganisespage/>}/>
      <Route axact path="Expedition-Short-Note" element={<ExpeditionShortnotepage/>}/>
      </Routes>
    <Footer/>
    </div>
    </Router>
  );
}

export default App;
